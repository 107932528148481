import { IExecDataProtector } from "@iexec/dataprotector";
import { ethers } from "ethers";
import { IExecWeb3mail } from "@iexec/web3mail";
import { getUserSettingsData, setUserSettingsData } from "../utils/helper";
import { UserSettingsType } from "../constants/Types";
import { WEB3MAIL_VOUCHER_PRIVATE_KEY } from "../config/env";

const IEXEC_RPC_URL = "https://bellecour.iex.ec";
const provider = new ethers.JsonRpcProvider(IEXEC_RPC_URL);
const WEB3MAIL_APP_ENS = "web3mail.apps.iexec.eth";
const WEB3MAIL_APP_ADDRESS = "0x781482C39CcE25546583EaC4957Fb7Bf04C277D2";
const WEB3MAIL_WORKERPOOL_ADDRESS = "prod-v8-bellecour.main.pools.iexec.eth";
const VOUCHER_ADDRESS = "0xE961ad04ED9cCcC190417d9749d418fDaEeC2071";

// Protect user email to create protectedData
export const protectUserEmail = async (
  email: string,
  userAddress: string,
  privateKey: string,
): Promise<{ protectedDataAddress: string; email: string }> => {
  try {
    const signer = new ethers.Wallet(privateKey, provider);

    // Use DataProtector to protect the email
    const dataProtector = new IExecDataProtector(signer);

    // Create a protected data object containing the email
    const protectedData = await dataProtector.core.protectData({
      data: { email },
      name: userAddress,
    });

    await dataProtector.core.grantAccess({
      protectedData: protectedData.address,
      authorizedApp: WEB3MAIL_APP_ENS,
      authorizedUser: VOUCHER_ADDRESS,
      numberOfAccess: 10000,
    });

    // Return the protectedData address
    return { protectedDataAddress: protectedData.address, email };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error protecting email:", error);
    throw error;
  }
};

// Get the saved email from user settings
export const getSavedEmail = async (
  userAddress: string,
): Promise<string | null> => {
  try {
    // Get current user settings
    const userSettings = (await getUserSettingsData()) || [];

    // Find the user's settings
    const userIndex = userSettings.findIndex(
      (user) => user.address === userAddress,
    );

    if (userIndex === -1) {
      return null;
    }

    const user = userSettings[userIndex];

    if (!user.emailNotification) {
      return null;
    }

    // Return the protected data address
    return user.emailNotification.email || null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error getting saved email:", error);
    return null;
  }
};

// Save the protected data reference to user's settings
export const saveProtectedEmailReference = async (
  userAddress: string,
  protectedDataAddress: string,
  email: string,
) => {
  try {
    // Get current user settings
    const userSettings = (await getUserSettingsData()) || [];

    // Find the user's settings
    const userIndex = userSettings.findIndex(
      (user) => user.address === userAddress,
    );

    if (userIndex !== -1) {
      // Only update the emailNotification field
      userSettings[userIndex] = {
        ...userSettings[userIndex],
        emailNotification: {
          protectedDataAddress,
          email,
          isEnabled: true,
        },
      };
    } else {
      // Create minimal user settings with just the required fields

      // Add a new minimal entry with just the required info
      userSettings.push({
        id: `user-${Date.now()}`,
        address: userAddress,
        emailNotification: {
          protectedDataAddress,
          email,
          isEnabled: true,
        },
      } as UserSettingsType); // Cast to UserSettingsType to satisfy TypeScript
    }

    // Save updated settings using setUserSettingsData
    await setUserSettingsData(userSettings);

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error saving protected email reference:", error);
    throw error;
  }
};

// Send email using the protected data address
export const sendWeb3Mail = async (
  recipientProtectedData: string,
  subject: string,
  content: string,
): Promise<boolean> => {
  try {
    const voucherSigner = new ethers.Wallet(
      WEB3MAIL_VOUCHER_PRIVATE_KEY,
      provider,
    );

    // Initialize Web3Mail
    const web3mail = new IExecWeb3mail(voucherSigner, {
      dappAddressOrENS: WEB3MAIL_APP_ENS,
      dappWhitelistAddress: WEB3MAIL_APP_ADDRESS,
    });

    // Send the email
    await web3mail.sendEmail({
      protectedData: recipientProtectedData,
      emailSubject: subject,
      emailContent: content,
      workerpoolAddressOrEns: WEB3MAIL_WORKERPOOL_ADDRESS,
      useVoucher: true,
    });

    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error sending email:", error);
    throw error;
  }
};

// Get Protected Data Details
export const getProtectedData = async (
  ownerAddress: string,
  eoaPrivateKey: string | null,
) => {
  if (!ownerAddress || !eoaPrivateKey) {
    throw new Error("Owner address or private key is missing");
  }

  try {
    const signer = new ethers.Wallet(eoaPrivateKey, provider);

    // Use DataProtector to protect the email
    const dataProtector = new IExecDataProtector(signer);

    const listProtectedData = await dataProtector.core.getProtectedData({
      owner: ownerAddress,
      requiredSchema: {
        email: "string",
      },
    });

    return listProtectedData;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error("Error getting protected data:", err);
    throw err;
  }
};
