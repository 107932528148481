import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { ArrowDown, ArrowRight } from "react-feather";
import { useNavigate } from "react-router-dom";
import { createConfig, getQuote } from "@lifi/sdk";

import { ethers } from "ethers";
import { Button } from "../../components/ui/button";
import AssetCard from "../TransactionComponents/AssetCard";
import { swapBridgeState } from "../../state/SwapBridgeState";
import {
  SwapBridgeStateType,
  UIDSForAddingAndRemovingAssetsType,
} from "../../constants/Types";
import AssetSelectionDrawer from "../AssetSelectionDrawer";
import {
  ASSET_SELECTION_TASK,
  STORAGE_KEYS,
  SWAP_ASSET_TYPE,
} from "../../constants/Enums";
import {
  log,
  validateSwapBridgeDetails,
  getChainDetails,
  getItemFromStorage,
} from "../../utils/helper";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";

type SwapAndBridgeType = {
  isBridge: boolean;
};

type SelectAssetDrawerType = {
  isOpen: boolean;
  isFromAsset: boolean;
};

type TransactionObjectType = {
  chainId: number;
  data: string;
  from: string;
  to: string;
  gasLimit: string;
  gasPrice: string;
  value: string;
};

type ApprovalDataType = {
  isRequired: boolean;
  fromTokenAddress: string;
  amount: string;
};

const SwapAndBridge = ({ isBridge }: SwapAndBridgeType) => {
  const navigate = useNavigate();
  const { chainID } = useWalletConfig();
  const [walletAddress, setWalletAddress] = useState<string>("");

  const [sendAmountInUSD, setSendAmountInUSD] = useState<string | undefined>(
    undefined,
  );
  const [receiveAmountInCrypto, setReceiveAmountInCrypto] = useState<
    string | undefined
  >(undefined);
  const [receiveAmountInUSD, setReceiveAmountInUSD] = useState<
    string | undefined
  >(undefined);
  const [executionToolName, setExecutionToolName] = useState<string>("");
  const [executionToolLogo, setExecutionToolLogo] = useState<string>("");

  const [transactionObject, setTransactionObject] = useState<
    TransactionObjectType | undefined
  >(undefined);

  const [approvedQuote, setApprovedQuote] = useState<any>();
  const [approvalData, setApprovalData] = useState<ApprovalDataType>({
    isRequired: false,
    fromTokenAddress: "",
    amount: "",
  });

  const [isAssetSelectionDrawerOpen, setIsAssetSelectionDrawerOpen] =
    useState<SelectAssetDrawerType>({
      isOpen: false,
      isFromAsset: true,
    });

  const [isProceedEnable, setIsProceedEnable] = useState(false);
  const [uidsForAddingAsset, setUidsForAddingAsset] =
    useState<UIDSForAddingAndRemovingAssetsType>({
      transactionUID: "",
      assetUID: "",
    });

  const [quoteLoading, setQuoteLoading] = useState<boolean>(false);

  // TODO: handle remove token in swaps?
  //   const [uidsToRemoveAsset, setUidsToRemoveAsset] =
  //     useState<UIDSForAddingAndRemovingAssetsType>({
  //       transactionUID: "",
  //       assetUID: "",
  //     });

  const navigation = (path: string, data: any) => {
    navigate(path, { state: { ...data } });
  };

  const [swapBridgeData, setSwapBridgeData] = useRecoilState(swapBridgeState);

  useEffect(() => {
    createConfig({
      integrator: "WalletX",
    });

    const currentChainDetails = getChainDetails(chainID);

    if (currentChainDetails) {
      const isOnlyEOA = currentChainDetails.isOnlyEoa || false;
      const address = getItemFromStorage(
        isOnlyEOA ? STORAGE_KEYS.SIGNER : STORAGE_KEYS.SMART_ACCOUNT,
      );

      setWalletAddress(address);
    }
  }, [chainID]);

  useEffect(() => {
    async function getQuotes() {
      if (
        swapBridgeData.length &&
        swapBridgeData[0]?.fromAsset?.tokenDetails?.address &&
        swapBridgeData[0]?.fromAsset?.tokenDetails?.amount &&
        swapBridgeData[0]?.toAsset?.tokenDetails?.address
      ) {
        setQuoteLoading(true);
        const quote = await getQuote({
          fromAddress: walletAddress,
          fromChain: chainID,
          toChain: chainID,
          fromToken: swapBridgeData[0].fromAsset.tokenDetails.address,
          toToken: swapBridgeData[0].toAsset?.tokenDetails?.address,
          fromAmount: ethers
            .parseUnits(
              swapBridgeData[0].fromAsset?.tokenDetails?.amount.toString(),
              swapBridgeData[0].fromAsset?.tokenDetails?.decimal,
            )
            .toString(),
        });

        if (
          quote.action.fromToken.address !==
          "0x0000000000000000000000000000000000000000"
        ) {
          setApprovalData({
            isRequired: true,
            fromTokenAddress: quote.action.fromToken.address,
            amount: quote.action.fromAmount,
          });
        }

        setSendAmountInUSD(quote.estimate.fromAmountUSD);

        setReceiveAmountInCrypto(
          (
            Number(quote.estimate.toAmountMin) /
            10 ** quote.action.toToken.decimals
          ).toString(),
        );

        setReceiveAmountInUSD(quote.estimate.toAmountUSD);

        setExecutionToolName(quote.toolDetails.name);
        setExecutionToolLogo(quote.toolDetails.logoURI);

        setTransactionObject(quote.transactionRequest as TransactionObjectType);
        setApprovedQuote(quote);

        log("sendAmountInUSD", sendAmountInUSD);
        log("transactionObject", transactionObject);
        setQuoteLoading(false);
      }
    }

    getQuotes();
  }, [swapBridgeData]);

  const openAssetSelectionDrawer = (
    _transactionUID: string,
    _assetUID: string,
    isFromAsset: boolean,
  ) => {
    setUidsForAddingAsset({
      transactionUID: _transactionUID,
      assetUID: _assetUID,
    });
    setIsAssetSelectionDrawerOpen({
      isOpen: true,
      isFromAsset,
    });
  };

  const openRemoveAssetModal = (transactionUID: string, assetUID: string) => {
    // TODO: handle remove token in swaps?
    // setUidsToRemoveAsset({ transactionUID, assetUID });
    log("openRemoveAssetModal:", { transactionUID, assetUID });
  };

  const closeAssetSelectionDrawer = (uid: string) => {
    if (uid === uidsForAddingAsset.transactionUID)
      setIsAssetSelectionDrawerOpen({
        isOpen: false,
        isFromAsset: false,
      });
  };

  const swapTokens = () => {
    const newSwapBridgeData = swapBridgeData.map(
      (data: SwapBridgeStateType) => ({
        ...data,
        toAsset: data.fromAsset,
        fromAsset: data.toAsset,
        // TODO: Should we swap chain as well?
        toChain: isBridge ? data.fromChain : data.toChain,
        fromChain: isBridge ? data.toChain : data.fromChain,
      }),
    );

    setSwapBridgeData(newSwapBridgeData);
  };

  const handleProceedButton = () => {
    if (
      validateSwapBridgeDetails(swapBridgeData) === true &&
      isProceedEnable &&
      swapBridgeData.length === 1
    ) {
      navigation("/dashboard/swap/approve", {
        transaction: transactionObject,
        quote: approvedQuote,
        approvalData,
      });
    }
  };

  return (
    <div className="flex flex-col w-full h-full pb-[150px] px-2">
      <div className="flex flex-col gap-2 bg-secondary-bg w-full border rounded-xl py-2 px-2 border-black mx-auto my-1 text-white shadow-md text-md">
        <div className="flex justify-center item-center gap-2">
          {swapBridgeData.length &&
          swapBridgeData[0].fromAsset &&
          Object.keys(swapBridgeData[0].fromAsset).length > 0 ? (
            <div className="w-full relative">
              {quoteLoading && (
                <div className="absolute top-1/2 -translate-y-1/2 w-full h-[85%] z-30 shine-animation rounded-xl"></div>
              )}
              <AssetCard
                assetCardSelectionTask={ASSET_SELECTION_TASK.SWAP_BRIDGE}
                key={swapBridgeData[0].fromAsset.assetUID}
                asset={swapBridgeData[0].fromAsset}
                transactionUID={swapBridgeData[0].uid}
                openAssetSelectionDrawer={() =>
                  openAssetSelectionDrawer(
                    swapBridgeData[0].uid,
                    swapBridgeData[0].fromAsset?.assetUID ||
                      SWAP_ASSET_TYPE.FROM_TOKEN,
                    true,
                  )
                }
                openRemoveAssetModal={() =>
                  openRemoveAssetModal(
                    swapBridgeData[0].uid,
                    swapBridgeData[0].fromAsset?.assetUID || "",
                  )
                }
                isProceedEnable={setIsProceedEnable}
                isReview={false}
              />
            </div>
          ) : (
            <div className="h-full w-full flex justify-between items-center px-2 py-1 gap-3">
              <button
                onClick={() => {
                  openAssetSelectionDrawer(
                    swapBridgeData[0].uid,
                    SWAP_ASSET_TYPE.FROM_TOKEN,
                    true,
                  );
                }}
                className="  bg-card-bg2 w-full h-full py-2 rounded-full text-white text-sm tracking-wide hover:scale-105"
              >
                Select from Asset
              </button>
            </div>
          )}
        </div>
      </div>

      <div
        onClick={swapTokens}
        className="flex justify-center gap-1 text-gray-100 m-2  mx-auto"
      >
        <div className="text-gray-100 hover:cursor-pointer group bg-card-bg2 p-2 rounded-full mx-auto active:rotate-[180deg] duration-100">
          <ArrowDown
            size={24}
            className="text-gray-100 group-hover:scale-105"
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 bg-secondary-bg w-full border rounded-xl py-2 px-2 border-black mx-auto my-1 text-white shadow-md text-md">
        <div className="flex justify-center item-center gap-2">
          {swapBridgeData.length &&
          swapBridgeData[0].toAsset &&
          Object.keys(swapBridgeData[0].toAsset).length > 0 ? (
            <div className="w-full relative">
              {quoteLoading && (
                <div className="absolute top-1/2 -translate-y-1/2 w-full h-[85%] z-30 shine-animation rounded-xl"></div>
              )}
              <AssetCard
                assetCardSelectionTask={ASSET_SELECTION_TASK.SWAP_BRIDGE}
                key={swapBridgeData[0].toAsset.assetUID}
                asset={swapBridgeData[0].toAsset}
                transactionUID={swapBridgeData[0].uid}
                openAssetSelectionDrawer={() =>
                  openAssetSelectionDrawer(
                    swapBridgeData[0].uid,
                    swapBridgeData[0].toAsset?.assetUID ||
                      SWAP_ASSET_TYPE.TO_TOKEN,
                    false,
                  )
                }
                openRemoveAssetModal={() =>
                  openRemoveAssetModal(
                    swapBridgeData[0].uid,
                    swapBridgeData[0].toAsset?.assetUID ||
                      SWAP_ASSET_TYPE.TO_TOKEN,
                  )
                }
                isProceedEnable={setIsProceedEnable}
                isReview={false}
              />
            </div>
          ) : (
            <>
              <div className="h-full w-full flex justify-between items-center px-2 py-1 gap-3">
                <button
                  onClick={() => {
                    openAssetSelectionDrawer(
                      swapBridgeData[0].uid,
                      SWAP_ASSET_TYPE.TO_TOKEN,
                      false,
                    );
                  }}
                  className="  bg-card-bg2 w-full h-full py-2 rounded-full text-white text-sm tracking-wide hover:scale-105"
                >
                  Select to Asset
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      {receiveAmountInCrypto &&
        receiveAmountInUSD &&
        executionToolLogo &&
        executionToolName && (
          <div className="grow w-full bg-secondary-bg rounded-xl p-3 mt-3 flex flex-col justify-between">
            <div className="flex justify-between items-center gap-4">
              <div className=" bg-card-bg2 w-full max-w-[40%] flex flex-col items-center gap-1 py-2 rounded-xl">
                <img
                  src={swapBridgeData[0].fromAsset?.tokenDetails?.logo}
                  alt="fromChainLogo"
                  className="object-scale-down max-h-8"
                />
                <p className="text-md">
                  {Number(
                    swapBridgeData[0].fromAsset?.tokenDetails?.amount,
                  ).toFixed(5)}
                </p>
                <p className="text-xs">${Number(sendAmountInUSD).toFixed(2)}</p>
              </div>
              <div className="grow flex justify-center items-center ">
                <ArrowRight />
              </div>
              <div className=" bg-card-bg2 w-full max-w-[40%] flex flex-col items-center gap-1 py-2 rounded-xl">
                <img
                  src={swapBridgeData[0].toAsset?.tokenDetails?.logo}
                  alt="toChainLogo"
                  className="object-scale-down max-h-8"
                />
                <p className="text-md">
                  {Number(receiveAmountInCrypto).toFixed(5)}
                </p>
                <p className="text-xs">
                  ${Number(receiveAmountInUSD).toFixed(2)}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col gap-3 px-2">
              <div className="w-full flex justify-between items-center">
                <p className="text-sm">Minimum received</p>
                <p className="text-sm">
                  {Number(receiveAmountInCrypto).toFixed(5)}{" "}
                  {swapBridgeData[0].toAsset?.tokenDetails?.symbol}
                </p>
              </div>
              <div className="w-full flex justify-between items-center">
                <p className="text-sm">Swapping via</p>
                <div className="flex justify-center items-center gap-2">
                  <img
                    src={executionToolLogo}
                    alt="executionToolLogo"
                    className="h-5 w-5 rounded-full"
                  />
                  <p className="text-xs">{executionToolName}</p>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex justify-center items-center w-full z-40 ">
        <Button
          disabled={
            !validateSwapBridgeDetails(swapBridgeData) &&
            !isProceedEnable &&
            !quoteLoading
          }
          onClick={handleProceedButton}
          size={"tsx"}
          variant={"tsxButton"}
          style={{
            opacity: isProceedEnable ? 1 : 0.5,
          }}
        >
          Proceed
        </Button>
      </div>

      <AssetSelectionDrawer
        drawerHeading={
          isAssetSelectionDrawerOpen.isFromAsset
            ? "Select From Asset"
            : "Select To Asset"
        }
        isOpen={isAssetSelectionDrawerOpen.isOpen}
        onClose={closeAssetSelectionDrawer}
        transactionUID={uidsForAddingAsset.transactionUID}
        assetUID={uidsForAddingAsset.assetUID}
        assetSelectionTask={ASSET_SELECTION_TASK.SWAP_BRIDGE}
      />
    </div>
  );
};

export default SwapAndBridge;
