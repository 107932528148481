import { ReactElement } from "react";
import {
  Book,
  Lock,
  Terminal,
  HelpCircle,
  Info,
  Maximize2,
  Link2,
  Mail,
} from "react-feather";

type settingOptionsType = {
  logo: ReactElement | string;
  title: string;
  description?: string;
  navigateTo: string;
  shouldNavigate: boolean;
  hasFunction: boolean;
  shouldExpandView?: boolean;
};

// eslint-disable-next-line import/prefer-default-export
export const settingOptions: settingOptionsType[] = [
  {
    logo: <Lock />,
    title: "Security",
    description: "Auto lock time, passwords, biometrics",
    navigateTo: "/settings/security",
    shouldNavigate: true,
    hasFunction: false,
  },
  // {
  //   logo: <Target />,
  //   title: "Transaction Settings",
  //   description: "Authentcation method for Sign, Approve & Transact",
  //   navigateTo: "/account/transaction-setting",
  //   shouldNavigate: true,
  //   hasFunction: false,
  // },
  // {
  //   logo: <Eye />,
  //   title: "Recovery Phrase",
  //   description: "Social Recovery, Seed Phrase",
  //   navigateTo: "/account/recovery/warning-page",
  //   shouldNavigate: true,
  //   hasFunction: false,
  // },
  {
    logo: <Maximize2 />,
    title: "Expand View",
    description: "Open the extension in a new tab",
    navigateTo: "",
    shouldNavigate: false,
    hasFunction: true,
    shouldExpandView: true,
  },
  {
    logo: <Mail />,
    title: "Email Notification",
    description: "Configure email alerts and updates",
    navigateTo: "/settings/email-notification",
    shouldNavigate: true,
    hasFunction: false,
  },
  {
    logo: <Book />,
    title: "Address Book",
    description: "Add and manage your contacts",
    navigateTo: "/settings/address-book",
    shouldNavigate: true,
    hasFunction: false,
  },
  {
    logo: <Terminal />,
    title: "Developer",
    description: "Testnet , Toggle Ads ",
    navigateTo: "/settings/developer-options",
    shouldNavigate: true,
    hasFunction: false,
  },
  {
    logo: <Link2 className="-rotate-45" />,
    title: "Wallet Connections",
    description: "Manage your dApp connections",
    navigateTo: "/settings/connections",
    shouldNavigate: true,
    hasFunction: false,
  },
  {
    logo: <HelpCircle />,
    title: "Help",
    description: "FAQs, Contact Us",
    navigateTo: "/settings/user-support",
    shouldNavigate: true,
    hasFunction: false,
  },
  {
    logo: <Info />,
    title: "About WalletX",
    description: "Socials, Version, Terms of Service",
    navigateTo: "/settings/about",
    shouldNavigate: true,
    hasFunction: false,
  },
];
