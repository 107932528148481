import { ChainId } from "@oneid-xyz/base";
import { OneID } from "@oneid-xyz/inspect";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { ethers } from "ethers";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router-dom";
import { useResolveAddress } from "@lens-protocol/react-web";
import { ChevronDown, Maximize } from "react-feather";
import Web3 from "web3";
import { motion, AnimatePresence } from "framer-motion";

import Modal from "../../components/common/Modal";
import LoaderComponent from "../../components/common/LoaderComponent";
import paste from "../../assets/paste.svg";
import AddressesCard from "../../components/AddressCard";
import { transferState } from "../../state/TransferState";
import RemoveModal from "../../components/Modal";
import { getUserSettingsData, log } from "../../utils/helper";
import {
  AddressInAddressBookType,
  AssetType,
  NFT,
  TransferStateType,
  UserSettingsType,
} from "../../constants/Types";
import { ASSET_TYPE, PAGE_TITLE_BUTTON_TYPE } from "../../constants/Enums";
import { ERROR_MESSAGES } from "../../constants/Messages";
import PageTitle from "../../components/PageTitle";
import AddressBookEntries from "../../components/AddressBookEntries";
import PageTransitionWrapper from "../../components/PageTransitionWrapper";
import useWalletConfig from "../../lib/store/hooks/useWalletConfig";
import IllustrationLoader from "../../components/IllustrationLoader";
import QrReader from "../../components/QrScanner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

const AddAddresses = () => {
  const [transferData, setTransferData] = useRecoilState(transferState);

  const [enteredAddresses, setEnteredAddresses] = useState<string>("");
  const [sendToAddresses, setSendToAddresses] = useState<string>("");
  const [inputLoader, setInputLoader] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState<number | null>(
    null,
  );
  const [userHandle, setUserHandle] = useState<string>("");
  const [cardAddress, setCardAddress] = useState<string>("");
  const [isBackModalOpen, setIsBackModalOpen] = useState<boolean>(false);
  const [userHandleAddressLoading, setUserHandleAddressLoading] =
    useState(false);

  const [loader, setLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isAddressBookButtonClicked, setIsAddressBookButtonClicked] =
    useState<boolean>(false);

  const [isAddAddressModalActive, setIsAddAddressModalActive] = useState(false);
  const [addressBookEntries, setAddressBookEntries] = useState<
    AddressInAddressBookType[]
  >([]);
  const [isLoadingQr, setIsLoadingQr] = useState(false);
  const [showQrScanner, setShowQrScanner] = useState(false);

  const { execute } = useResolveAddress();
  const {
    chainID: chainId,
    provider,
    smartAccountAddress,
    eoaAddress,
  } = useWalletConfig();
  const navigate = useNavigate();
  const location = useLocation();
  const locationState: NFT = location.state?.nftData;

  let placeholderValue;

  if (chainId === 137 || chainId === 80002) {
    placeholderValue = "Enter an address / OneID / Lens ID";
  } else if (chainId === 1 || chainId === 11155111 || chainId === 5) {
    placeholderValue = "Enter an address / OneID / ENS ID";
  } else {
    placeholderValue = "Enter an address / OneID";
  }

  const openBackModal = () => {
    if (transferData.length > 0) {
      setIsBackModalOpen(true);
    } else {
      navigate("/dashboard");
    }
  };

  const goToAddTokens = () => {
    navigate("/dashboard/transaction/add-tokens");
  };

  const closeBackModal = () => {
    setIsBackModalOpen(false);
  };

  const handleBack = () => {
    setTransferData([]);
    navigate("/dashboard");
  };

  const receivedAddress = (address: string) => {
    setSendToAddresses(address);
  };

  const handleCardClick = (index: any) => {
    if (selectedCardIndex === index) {
      setSelectedCardIndex(null);
      setSendToAddresses("");
    } else {
      setSelectedCardIndex(index);
    }

    setEnteredAddresses("");
  };

  const isEthereumAddress = (address: any) => {
    if (ethers.isAddress(address)) {
      setLoader(false);
      setErrorMessage("");

      return true;
    }

    return false;
  };

  const showQrModal = (state: boolean) => {
    setCardAddress("");
    setShowQrScanner(state);
  };

  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();

      setEnteredAddresses(text);
      setCardAddress(text);
    } catch (err) {
      log("Failed to read clipboard contents: ", err);
    }
  };

  const resolveOneId = async (_oneId: string) => {
    const oneId = new OneID();

    await oneId.systemConfig.initConfig();
    const chain: ChainId = `${chainId}` as ChainId;
    const data = await oneId.getWalletsByID(_oneId, chain);

    if (!data || !data.length || !data[0]?.address) {
      throw new Error(ERROR_MESSAGES.ONE_ID_NOT_FOUND);
    } else {
      const address = data[0]?.address;

      if (address) {
        setUserHandle(_oneId);
        setCardAddress(address);
        setUserHandleAddressLoading(false);

        setLoader(false);
        setErrorMessage("");
      } else {
        setErrorMessage(ERROR_MESSAGES.ONE_ID_NOT_FOUND);
      }
    }
  };

  const resolveLensId = async (_lensId: string) => {
    const lensId = _lensId.split(".");
    const result = await execute({ handle: `lens/${lensId[0]}` });

    if (result.isFailure()) {
      setErrorMessage(ERROR_MESSAGES.LENS_NOT_FOUND);
      return;
    }

    setUserHandle(_lensId);
    if (result.value) {
      setCardAddress(result.value);
      setUserHandleAddressLoading(false);

      setLoader(false);
      setErrorMessage("");
    }

    setErrorMessage(ERROR_MESSAGES.LENS_NOT_FOUND);
  };

  const resolveENSId = async (_ensId: string) => {
    try {
      const address = (await provider?.resolveName(_ensId)) ?? "";

      if (address !== null) {
        setUserHandle(_ensId);
        setCardAddress(address);
        setUserHandleAddressLoading(false);

        setLoader(false);
        setErrorMessage("");
      } else {
        setErrorMessage(ERROR_MESSAGES.ENS_NOT_FOUND);
      }
    } catch (e: any) {
      if (e.message.includes("does not support ENS")) {
        setErrorMessage(ERROR_MESSAGES.ENS_NOT_SUPPORTED);
      }
    }
  };

  const validateHandle = async (_userID: string) => {
    const ensHandle = _userID.substring(_userID.length - 4);
    const lensHandle = _userID.substring(_userID.length - 5);

    setUserHandleAddressLoading(true);

    if (
      ensHandle === ".eth" &&
      (chainId === 1 || chainId === 11155111 || chainId === 5)
    ) {
      resolveENSId(_userID);
    } else if (
      lensHandle === ".lens" &&
      (chainId === 137 || chainId === 80002)
    ) {
      resolveLensId(_userID);
    } else if (
      ensHandle === ".eth" &&
      (chainId !== 1 || chainId !== 11155111 || chainId !== 5)
    ) {
      setErrorMessage(ERROR_MESSAGES.ENS_NOT_SUPPORTED);
    } else if (
      lensHandle === ".lens" &&
      (chainId !== 137 || chainId !== 80002)
    ) {
      setErrorMessage(ERROR_MESSAGES.LENS_NOT_SUPPORTED);
    } else {
      try {
        await resolveOneId(_userID);
      } catch (e) {
        setErrorMessage(ERROR_MESSAGES.ONE_ID_NOT_REGISTERED);
      }
    }
  };

  const handleProceed = (
    _address: string,
    _name: string,
    _userHandle: string,
  ) => {
    const uuid = crypto.randomUUID();
    let address = "";

    if (sendToAddresses === "") {
      address = _address;
    } else {
      address = sendToAddresses;
    }

    if (locationState) {
      const assets: AssetType = {
        assetUID: crypto.randomUUID(),
        assetType: ASSET_TYPE.NFT,
        nftDetails: {
          address: locationState.address,
          collectionName: locationState.collectionName,
          id: locationState.id,
          imageUrl: locationState.imageUrl,
          name: locationState.name,
          description: locationState.description,
        },
      };

      setTransferData((prevAddresses: TransferStateType[]) => [
        ...prevAddresses,
        {
          uid: uuid,
          name: _name || "",
          address,
          usersDomainName: _userHandle || "",
          assets: [assets],
        },
      ]);
    } else {
      setTransferData((prevAddresses) => [
        ...prevAddresses,
        {
          uid: uuid,
          name: _name || "",
          usersDomainName: _userHandle || "",
          address,
          assets: [],
        },
      ]);
    }

    setLoader(false);
    setErrorMessage("");

    log("Transfer Data : ", transferData, "info");
    navigate("/dashboard/transaction/add-tokens");
  };

  const getUseNameFromAddressBookIfAvailable = (_address: string) => {
    let name = "";

    try {
      const addressBookOfSWA = addressBookEntries || [];

      const existingAddressIndex = addressBookOfSWA.findIndex(
        (entry: AddressInAddressBookType) => entry.address === _address,
      );

      if (existingAddressIndex !== -1) {
        // Address already exists, update the name
        const updatedAddressBookOfSWA = [...addressBookOfSWA];

        name = updatedAddressBookOfSWA[existingAddressIndex].name;
      } else {
        return name;
      }

      return name;
    } catch (error) {
      log("this is the error ", error, "warning");
    }

    return name;
  };

  const generateAddressCard = (
    _enteredAddresses: string,
    _userHandle: string,
  ) => {
    const name = getUseNameFromAddressBookIfAvailable(_enteredAddresses);

    return (
      <AddressesCard
        name={name || ""}
        usersDomainName={_userHandle || ""}
        address={_enteredAddresses}
        isSelected={selectedCardIndex === 1}
        getClickedAddress={receivedAddress}
        onClick={() => {
          handleCardClick(1);

          if (isEthereumAddress(_enteredAddresses)) {
            setSendToAddresses(_enteredAddresses);
          }

          handleProceed(_enteredAddresses, name, _userHandle);
          setErrorMessage(ERROR_MESSAGES.INVALID_LENS_ENS);
        }}
        isAddAddressModalActive={(_isActive) =>
          setIsAddAddressModalActive(_isActive)
        }
      />
    );
  };

  const handleInputChange = async (e: any) => {
    const inputValue = e.target.value;

    setInputLoader(true);

    setLoader(true);
    setErrorMessage("");
    setUserHandle("");
    setCardAddress(inputValue);
    setEnteredAddresses(inputValue);
    let isValidAddressInput = false;

    if (!inputValue.length) {
      setInputLoader(false);
      return;
    }

    try {
      Web3.utils.toChecksumAddress(inputValue);
      isValidAddressInput = true;
    } catch (error: any) {
      log("Not a valid address");
    }

    if (!isValidAddressInput) {
      await validateHandle(inputValue);
    }

    setInputLoader(false);
  };

  const handleFocus = () => {
    setSendToAddresses("");
    handleCardClick(null);
    setIsAddressBookButtonClicked(false);
  };

  const addressesAndHandles: any = [];
  const uniqueAddresses = new Set();

  transferData.forEach(({ address, name, usersDomainName }) => {
    if (!uniqueAddresses.has(address)) {
      uniqueAddresses.add(address);
      addressesAndHandles.push({ address, name, usersDomainName });
    }
  });

  const generateUniqueAddressCards = () =>
    addressesAndHandles.map((addressAndHandles: any, index: number) => (
      <AddressesCard
        key={index}
        name={addressAndHandles.name}
        address={addressAndHandles.address}
        usersDomainName={addressAndHandles.usersDomainName}
        isSelected={selectedCardIndex === index}
        getClickedAddress={receivedAddress}
        onClick={() => {
          log(
            "this is address and handles in generateUniqueAddressCards function ",
            addressAndHandles,
            "error",
          );
          handleCardClick(index);
          if (isEthereumAddress(addressAndHandles.address)) {
            setSendToAddresses(addressAndHandles.address);
          }

          handleProceed(
            addressAndHandles.address,
            addressAndHandles.name,
            addressAndHandles.usersDomainName,
          );
        }}
      />
    ));

  const fetchAddressBookEntries = async () => {
    try {
      const usersSettings: UserSettingsType[] = await getUserSettingsData();

      const userIndex = usersSettings.findIndex(
        (user: UserSettingsType) => user.address === eoaAddress,
      );

      if (userIndex !== -1) {
        const userAddressBook = usersSettings[userIndex].settings.addressBook;

        setAddressBookEntries(userAddressBook);
      } else {
        setAddressBookEntries([]);
      }
    } catch (error) {
      log("Error fetching address book entries:", error);
    }
  };

  useEffect(() => {
    if (isEthereumAddress(enteredAddresses) || isEthereumAddress(cardAddress))
      setIsValid(true);
    else {
      setErrorMessage(errorMessage || ERROR_MESSAGES.INVALID_USER_ADDRESS);
      setIsValid(false);
    }
  }, [
    enteredAddresses,
    cardAddress,
    selectedCardIndex,
    sendToAddresses,
    userHandleAddressLoading,
  ]);

  useEffect(() => {
    if (isValid) {
      setSendToAddresses(cardAddress);
      setLoader(false);
      setErrorMessage("");
    } else setErrorMessage(errorMessage || ERROR_MESSAGES.INVALID_USER_ADDRESS);
  }, [isValid]);

  useEffect(() => {
    if (enteredAddresses === "") {
      setErrorMessage("");
      setLoader(false);
    }
  }, [enteredAddresses]);

  useEffect(() => {
    fetchAddressBookEntries();
  }, [smartAccountAddress]);

  const showLoaders = () => {
    if (inputLoader)
      return (
        <div className="w-fit mx-auto mt-5">
          <IllustrationLoader height="1.4em" time={0.35} />
        </div>
      );

    if (loader === true || errorMessage !== "")
      return (
        <div className=" absolute w-full ">
          {errorMessage !== "" ? (
            <>
              <p className="text-red-500 text-center text-md w-[80%] mx-auto">
                {enteredAddresses !== "" && errorMessage}
              </p>
            </>
          ) : (
            <div className="w-fit mx-auto mt-6">
              <IllustrationLoader height="1.4em" time={0.35} />
            </div>
          )}
        </div>
      );

    return (
      <>
        {isValid && (
          <div
            // className={`fixed top-0 left-0 w-full  transform transition-opacity duration-1000 ease-in opacity-0 z-20  ${
            className={`top-0 left-0 w-full  transform transition-opacity duration-1000 ease-in opacity-0 z-20  ${
              isValid && "opacity-100"
            } ${isAddAddressModalActive === true ? "h-full" : ""}  `}
          >
            <div className="absolute top-0 w-full z-50 bg-primary-bg">
              {generateAddressCard(cardAddress, userHandle)}
            </div>
          </div>
        )}
      </>
    );
  };

  async function handleSubmitQrUri(e: any) {
    try {
      setIsLoadingQr(true);
      e.preventDefault();

      const address = e.target.uri.value;

      const slicedAddress = address.split(":")[1];
      const checksumAddress = ethers.getAddress(slicedAddress);

      log("checksumAddress: ", checksumAddress, "info");

      setIsLoadingQr(false);
      showQrModal(false);
      setCardAddress(checksumAddress);
    } catch (err: any) {
      setIsLoadingQr(false);
      showQrModal(false);
      toast.error("Invalid address!");

      log("Address scan error", err, "error");
    }
  }

  const qrModalChild = () => {
    if (isLoadingQr) {
      return <LoaderComponent />;
    }

    return (
      <div className="align-center flex flex-col justify-center">
        <QrReader onScanSuccessful={handleSubmitQrUri} />
      </div>
    );
  };

  return (
    <PageTransitionWrapper back={location.state?.back}>
      <div className="mx-auto overflow-hidden no-scrollbar bg-primary-bg  h-full w-full text-white text-md ">
        <PageTitle
          title="Select Address"
          leftButtonIcon={PAGE_TITLE_BUTTON_TYPE.ARROW_LEFT}
          onLeftButtonClick={() => openBackModal()}
          rightButtonIcon={
            transferData.length > 0
              ? PAGE_TITLE_BUTTON_TYPE.ARROW_RIGHT
              : PAGE_TITLE_BUTTON_TYPE.NONE
          }
          onRightButtonClick={() => goToAddTokens()}
        />

        <div className="flex w-full justify-between items-center gap-2 auto border border-gray-300 rounded-lg mt-4 mb-1 p-2">
          <input
            type="text"
            placeholder={placeholderValue}
            className="w-full focus:outline-none pl-1 bg-transparent pr-2 text-sm"
            value={enteredAddresses}
            onChange={handleInputChange}
            onFocus={handleFocus}
          />
          <img
            src={paste}
            alt="paste"
            onClick={handlePaste}
            className="h-4 w-4 hover:bg-card-bg2 cursor-pointer"
          />
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <Maximize
                  onClick={() => showQrModal(true)}
                  size={16}
                  className="animate-pulse cursor-pointer"
                />
              </TooltipTrigger>
              <TooltipContent
                className="bg-secondary-bg text-white border-black mt-[20px]"
                side="left"
              >
                <p className="whitespace-nowrap text-sm text-wrap w-[150px]">
                  Scan QR
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        {showQrScanner && (
          <Modal
            isOpen={showQrScanner}
            onClose={() => showQrModal(false)}
            children={qrModalChild()}
            headerText={"Scan Address QR"}
            modalClass=""
          />
        )}

        <div className="w-full h-full overflow-hidden flex flex-col">
          <div
            className="w-full h-fit flex items-center justify-center cursor-pointer select-none"
            style={{
              transition: "all 1s ease-in-out",
            }}
            onClick={() =>
              setIsAddressBookButtonClicked(!isAddressBookButtonClicked)
            }
          >
            <p
              className={`relative w-full text-sm text-right font-semibold tracking-wide transition-all duration-500 z-10 bg-primary-bg
              ${isAddressBookButtonClicked ? "text-white" : "text-neutral-500"}
              `}
            >
              Address Book
            </p>
            <ChevronDown
              size={20}
              className={`mx-2 transition-all duration-500 ${
                isAddressBookButtonClicked
                  ? "rotate-180 text-white"
                  : "text-neutral-500"
              }`}
            />
          </div>
          <AnimatePresence mode="wait">
            {!isAddressBookButtonClicked ? (
              <div className="relative h-full max-h-[85%] overflow-scroll">
                {showLoaders()}
                {transferData.length > 0 && (
                  <div
                    className={`  ${
                      enteredAddresses !== "" ||
                      userHandle !== "" ||
                      isValid === true
                        ? "translate-y-16"
                        : ""
                    } transition duration-4000  translate`}
                  >
                    <p className="text-md text-center font-semibold tracking-wide text-neutral-500 py-2 bg-primary-bg w-full sticky -top-1 z-10">
                      Selected Addresses
                    </p>
                    {generateUniqueAddressCards()}
                  </div>
                )}
              </div>
            ) : (
              <motion.div
                key={"addressBook"}
                className="w-full h-full max-h-[85%] overflow-auto "
                initial={{ y: -200, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -150, opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                <AddressBookEntries
                  onClickOfAddressCard={(_address: string, _name: string) => {
                    handleProceed(_address, _name, "");
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <RemoveModal
          isOpen={isBackModalOpen}
          onCancel={closeBackModal}
          onRemove={handleBack}
          message="Do you want to delete all the transactions?"
          actionBtnName="Delete"
        />
      </div>
    </PageTransitionWrapper>
  );
};

export default AddAddresses;
