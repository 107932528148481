"use strict";
exports.__esModule = true;
exports.ONRAMP_APP_ID = exports.ONRAMP_URL = exports.WEB3MAIL_VOUCHER_PRIVATE_KEY = exports.ENCRYPTION_PASSWORD = exports.WALLETCONNECT_PROJECT_ID = exports.FIAT_API_KEY = exports.FIAT_API = exports.FP_KEY = exports.TG_BOT_TOKEN = exports.USER_DASHBOARD_URL = exports.BASE_API_XTENSION_HEADER = exports.DUMMY_AD_CHAINS = exports.DUMMY_AD_WEBSITE = exports.DUMMY_AD_NAME = exports.DUMMY_AD_URL = exports.BASE_API_URL = exports.BASE_URL = exports.NODE_ENV = exports.MIXPANEL_TOKEN = exports.SERVICES_ENV = exports.DAPP_CONNECTION_ENV = exports.DEFAULT_NETWORK = void 0;
var ETHEREUM_SEPOLIA = {
    NAME: process.env.REACT_APP_TESTNET_NAME || "",
    PAYMASTER: process.env.REACT_APP_TESTNET_PAYMASTER || "",
    BUNDLER: process.env.REACT_APP_TESTNET_BUNDLER || "",
    CHAIN_ID: Number(process.env.REACT_APP_TESTNET_CHAIN_ID) || 11155111,
    RPC: process.env.REACT_APP_TESTNET_RPC || "",
    EXPLORER_URL: process.env.REACT_APP_TESTNET_EXPLORER_URL || ""
};
var POLYGON_MAINNET = {
    NAME: process.env.REACT_APP_MAINNET_NAME || "",
    PAYMASTER: process.env.REACT_APP_MAINNET_PAYMASTER || "",
    BUNDLER: process.env.REACT_APP_MAINNET_BUNDLER || "",
    CHAIN_ID: Number(process.env.REACT_APP_MAINNET_CHAIN_ID) || 137,
    RPC: process.env.REACT_APP_MAINNET_RPC || "",
    EXPLORER_URL: process.env.REACT_APP_MAINNET_EXPLORER_URL || ""
};
exports.DEFAULT_NETWORK = process.env.REACT_APP_NODE_ENV !== "development"
    ? POLYGON_MAINNET
    : ETHEREUM_SEPOLIA;
exports.DAPP_CONNECTION_ENV = {
    WALLET_WORD_SPLIT: process.env.REACT_APP_WALLET_WORD_SPLIT || ""
};
exports.SERVICES_ENV = {
    MORALIS_NFT_API: process.env.REACT_APP_MORALIS_NFT_API || ""
};
exports.MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "";
exports.NODE_ENV = process.env.REACT_APP_NODE_ENV || "development";
exports.BASE_URL = process.env.REACT_APP_BASE_URL || "";
exports.BASE_API_URL = process.env.REACT_APP_BASE_API_URL || "";
exports.DUMMY_AD_URL = "".concat(exports.BASE_URL).concat(process.env.REACT_APP_DUMMY_AD_URL) || "";
exports.DUMMY_AD_NAME = process.env.REACT_APP_DUMMY_AD_NAME || "";
exports.DUMMY_AD_WEBSITE = process.env.REACT_APP_DUMMY_AD_WEBSITE || "";
exports.DUMMY_AD_CHAINS = [
    Number(process.env.REACT_APP_DUMMY_AD_CHAINS) || 137,
] || [137];
exports.BASE_API_XTENSION_HEADER = process.env.REACT_APP_BASE_API_XTENSION_HEADER;
exports.USER_DASHBOARD_URL = process.env.REACT_APP_USER_DASHBOARD_URL || "";
exports.TG_BOT_TOKEN = process.env.REACT_APP_TG_BOT_TOKEN || "";
exports.FP_KEY = process.env.REACT_APP_FP_KEY || "";
exports.FIAT_API = process.env.REACT_APP_FIAT_API || "";
exports.FIAT_API_KEY = process.env.REACT_APP_FIAT_API_KEY || "";
exports.WALLETCONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || "";
exports.ENCRYPTION_PASSWORD = process.env.REACT_APP_ENCRYPTION_PASSWORD || "";
exports.WEB3MAIL_VOUCHER_PRIVATE_KEY = process.env.REACT_APP_WEB3MAIL_VOUCHER_PRIVATE_KEY || "";
exports.ONRAMP_URL = process.env.REACT_APP_ONRAMP_URL || "";
exports.ONRAMP_APP_ID = process.env.REACT_APP_ONRAMP_APP_ID || "";
