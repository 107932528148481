import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Check, Copy, ExternalLink, Loader } from "react-feather";
import { supportedChainsState } from "../state/SupportedChainsState";

import { copyToClipboard } from "../utils/helper";
import approved from "../assets/approve-txn.svg";
import failed from "../assets/failed-txn.svg";
import { TransactionDrawerType } from "../constants/Types";
import { TRANSACTION_STATUS } from "../constants/Enums";
import { Button } from "./ui/button";
import useWalletConfig from "../lib/store/hooks/useWalletConfig";
import { sendWeb3Mail } from "../services/web3mail";

const TransactionDrawer = ({
  isOpen,
  transactionDetails,
  hasProtectedData = false,
  protectedDataAddress = "",
}: TransactionDrawerType) => {
  const navigate = useNavigate();
  const { chainID: chainId } = useWalletConfig();
  const [explorer, setExplorer] = useState<string>();
  const [isTransactionHashCopied, setIsTransactionHashCopied] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [includeTransactionDetails, setIncludeTransactionDetails] =
    useState<boolean>(true);
  const [supportedChains] = useRecoilState(supportedChainsState);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>("");

  useEffect(() => {
    const fetchExplorer = () => {
      const chain = supportedChains.find(
        (_chain) => _chain.chainId === chainId,
      );

      if (chain) {
        setExplorer(chain.explorerUrl);
      }
    };

    fetchExplorer();
  }, [explorer]);

  // Reset success state when message changes
  useEffect(() => {
    if (sendSuccess) {
      setSendSuccess(false);
    }
  }, [message]);

  const sendMail = async () => {
    if (!protectedDataAddress || isSending) return;

    try {
      setIsSending(true);
      setSendSuccess(false);

      const subject = "Transaction Notification from WalletX";
      const content = `
      ${message}\n\n
      ${
        includeTransactionDetails
          ? `
      Transaction Hash: ${transactionDetails.data.hash}
      `
          : ""
      }
      `;

      await sendWeb3Mail(protectedDataAddress, subject, content);

      setMessage("");
      setSendSuccess(true);

      // Reset success state after 3 seconds
      setTimeout(() => {
        setSendSuccess(false);
      }, 3000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error sending notification:", error);
      setSendSuccess(false);
      setNotificationMessage("Failed to send email notification");
    } finally {
      setIsSending(false);
    }
  };

  return (
    <div
      className={`${
        isOpen ? "bottom-0" : " translate-y-full"
      } fixed flex flex-col gap-4 justify-start items-center bottom-0 left-1/2 translate-x-[-50%] w-full bg-secondary-bg border border-gray-300 text-white rounded-t-3xl mt-10 px-5 py-6 transition duration-1000 transform z-50`}
      style={{ height: hasProtectedData ? "450px" : "350px" }}
    >
      <div
        className={`flex flex-col gap-4 justify-center items-center transition ease-in duration-3000 delay-700`}
      >
        <img
          className="h-16 transition ease-in duration-3000"
          src={transactionDetails.isError ? failed : approved}
          alt={`${transactionDetails.isError ? "failed" : "approved"}`}
        />

        <h1 className="text-lg font-semibold">
          Status:
          <span
            className={
              transactionDetails.isError ? "text-red-500" : `text-green-500`
            }
          >
            {" "}
            {transactionDetails.isError
              ? TRANSACTION_STATUS.TRANSACTION_FAILED
              : TRANSACTION_STATUS.TRANSACTION_CONFIRMED}
          </span>
        </h1>
      </div>
      <div className="w-full overflow-y-auto flex-1 py-2">
        {!transactionDetails.isError && (
          <div className="flex flex-col gap-4 px-2 justify-center items-center">
            <div
              onClick={() => {
                copyToClipboard(transactionDetails.transactionHash);
                setIsTransactionHashCopied(true);
              }}
              className="flex justify-center items-center gap-2 rounded-xl px-3 py-3 bg-card-bg2 cursor-pointer text-sm hover:scale-105 w-full"
            >
              {!isTransactionHashCopied ? (
                <>
                  <Copy size={18} />
                  <p> Copy Transaction Hash</p>
                </>
              ) : (
                <>
                  <Check color="green" size={18} />
                  <p> Copied</p>
                </>
              )}
            </div>
            <div className="flex justify-center items-center gap-2 rounded-lg px-3 py-3 w-full text-center bg-card-bg2 hover:scale-105 text-sm">
              <ExternalLink size={17} />
              <a
                href={`${explorer}/tx/${transactionDetails.transactionHash}`}
                target="_blank"
              >
                View on block explorer
              </a>
            </div>

            {hasProtectedData && (
              <div className="w-full mt-6 space-y-3">
                {notificationMessage ? (
                  <div className="w-full text-center py-4 px-4 bg-green-500 bg-opacity-20 border border-green-600 rounded-lg text-green-300 h-16 flex items-center justify-center">
                    <Check size={18} className="mr-2 text-green-400" />
                    {notificationMessage}
                  </div>
                ) : (
                  <>
                    <div className="flex w-full items-stretch">
                      <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Hey! I just sent you a trans..."
                        className="flex-1 px-4 py-3 h-12 rounded-lg bg-card-bg2 text-white border border-gray-600 focus:outline-none focus:border-gray-400 text-base"
                      />
                      <Button
                        onClick={() => {
                          // Set notification message immediately on click
                          setNotificationMessage(
                            "Your message will be sent to the recipient via email",
                          );
                          // Then call the sendMail function
                          sendMail();
                        }}
                        disabled={isSending}
                        className={`ml-2 h-12 min-w-[100px] font-medium px-4 rounded-lg flex items-center justify-center transition-colors ${
                          sendSuccess
                            ? "bg-green-500 hover:bg-green-600 text-white"
                            : "bg-green-100 hover:bg-green-200 text-green-800"
                        }`}
                      >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {isSending ? (
                          <>
                            <Loader size={16} className="animate-spin mr-2" />
                            Sending
                          </>
                        ) : sendSuccess ? (
                          <>
                            <Check size={16} className="mr-2" />
                            Sent!
                          </>
                        ) : (
                          <>
                            Notify <span className="ml-1">✉️</span>
                          </>
                        )}
                      </Button>
                    </div>

                    <div className="flex items-center w-full mt-2">
                      <input
                        type="checkbox"
                        id="includeDetails"
                        checked={includeTransactionDetails}
                        onChange={() =>
                          setIncludeTransactionDetails(
                            !includeTransactionDetails,
                          )
                        }
                        className="mr-2 h-5 w-5 accent-green-500"
                      />
                      <label
                        htmlFor="includeDetails"
                        className="text-sm text-gray-300"
                      >
                        Include Transaction Details
                      </label>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="px-2 py-2 w-full flex justify-evenly gap-4 mt-auto">
        <Button
          onClick={() => {
            navigate("/dashboard");
          }}
          className="w-full bg-card-bg2 hover:bg-primary-bg py-3 text-base"
        >
          Dashboard
        </Button>
        <Button
          onClick={() => {
            navigate("/dashboard/activity");
          }}
          className="w-full bg-card-bg2 hover:bg-primary-bg py-3 text-base"
        >
          Activity
        </Button>
      </div>
    </div>
  );
};

export default TransactionDrawer;
